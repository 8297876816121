.basic-button {
  border: none;
  box-shadow: none;
  text-align: center;
  display: inline-block;
  padding: 10px 15px;
  padding-top: 8px;
  cursor: pointer;
  color: #eee;
  width: 120px;
  height: 40px;
  font-size: 16px;
  font-weight: bold;
  background-color: rgb(0, 79, 176);
  border-radius: 30px;
}
.search-button {
  position: relative;
  top:50px;
}
.basic-button:hover {
  color:white;
  box-shadow: 0px 0px 10px #ccc;
}

.search-button-component {
  border: none;
  box-shadow: none;
  text-align: center;
  display: inline-block;
  padding: 10px 15px;
  padding-top: 8px;
  cursor: pointer;
  color: #eee;
  width: 200px;
  height: 50px;
  font-size: 16px;
  font-weight: bold;
  background-color: transparent;
  position: relative;
  top:-10px;
}

.search-button-component:hover {
  color: white;
}

.search-button-disabled {
  opacity: 0.3;
  -webkit-user-select: none;
  user-select: none;
}