
.airport-suggestions {
  padding:15px;
  font-size: 13px;
  background-color:white;
  border-radius: 10px;
  box-shadow: 0px 5px 20px #ddd;
  color: #333;
  user-select: none;
}

.airport-suggestion {
  padding-top:5px;
  padding-bottom:5px;
  cursor: pointer;
  user-select: none;
}

.airport-suggestion:hover {
font-weight: bold;
}