.error-text-container {
  font-size: 13px;
  margin-left: auto;
  margin-right: auto;
  background-color: #dd0000;
  border: 1px solid red;
  border-radius: 30px;
  padding: 5px 12px;
  display: block;
  width: auto;
  max-width: 450px;
}
.error-text-container, .error-text-container > * {
  color: white;
}