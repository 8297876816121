.modal-outer {
  background-color:rgba(0, 0, 0, 0.55);
  opacity: 1;
  position: absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  width: 100%;
  height:100%;
  display:flex;
  justify-content: center;
  align-items: center;
}

.modal-inner {
  background-color:white;
  opacity: 1;
  min-width:100px;
  width:auto;
  display:inline-block;
  margin-left:auto;
  margin-right:auto;
  padding:30px;
  border-radius:10px;
  box-shadow: 0px 0px 20px #33333351;
}