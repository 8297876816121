.filterPopover {
  margin-top: 20px;
  padding: 25px;
  background-color: white;
  border:1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 1px 3px 0 rgb(60 64 67 / 30%), 0 4px 8px 3px rgb(60 64 67 / 15%);
  min-width: 240px;
  min-height: 50px;
}
.filterPopoverTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.filterPopoverBody {
  margin-top: 25px;
  width: 100%;
  min-height: 50px;
  max-height: 250px;
  overflow-y:auto;
  overflow-x:hidden;

}

.filterRadioButton {
  -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
  transform: scale(1.5);
  margin-right: 13px;
}