.fnz-button {
  border: none;
  box-shadow: 0px 0px 3px rgba(0.3,0.3,0.3,0.3);
  text-align: center;
  display: inline-block;
  padding: 10px 20px;
  padding-top: 8px;
  color: #eee;
  width: auto;
  height: 38px;
  font-size: 14px;
  background-color: rgb(0, 79, 176);
  border-radius: 20px;
  cursor: pointer;
}

.fnz-button:hover {
  color:white;
  box-shadow: 0px 0px 10px #ccc;
}

.button-small {
  font-size:11px;
  padding: 5px 14px;
  height: 24px;
  line-height: 12px;
}

.button-secondary {
  background-color: transparent;
  color: #666;
  box-shadow: none;
  border: 1px solid #aaa;
}

.button-danger {
  background-color:#ee0000;
}

.button-secondary:hover {
  background-color: transparent;
  color: #333;
  box-shadow: none;
  border: 1px solid #777;
}

.fnz-button-component {
  border: none;
  box-shadow: none;
  text-align: center;
  display: inline-block;
  padding: 10px 15px;
  padding-top: 8px;
  cursor: pointer;
  color: #eee;
  width: 200px;
  height: 50px;
  font-size: 16px;
  font-weight: bold;
  background-color: transparent;
  position: relative;
  top:-10px;
}

.fnz-button-component:hover {
  color: white;
}

.button-disabled {
  opacity: 0.3;
  -webkit-user-select: none;
  user-select: none;
}

.fnz-button-muted {
  background-color: rgb(82, 104, 131);
}

.fnz-button-linkstyle {
  background:none !important;
  border:none;
  cursor: pointer;
}

.fnz-button-linkstyle:hover {
  color: rgb(0, 79, 176)
}

.fnz-round-remove-button {
  border: 1px solid #eee;
  padding: 5px;
  border-radius:15px;
  width: 24px;
  height:24px;
  text-align: center;
  font-size: 10px;
  background-color:#eee;
  cursor: pointer;
  opacity: 0.8;
  color: #666;
  margin-left:5px;
}

.fnz-round-remove-button-red {
  background-color:red;
  opacity: 0.5;
  color:white;
}

.fnz-round-remove-button:hover {
  opacity: 1;
  background-color: #fafafa;
}

.fnz-round-remove-button-red:hover {
  opacity: 1;
  background-color:red;
  color:white;
}