*,
*::before,
*::after {
  font-family: 'Lato';
  color: #111;
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
}

input:focus {
  outline:none;
}

select {
  appearance: none;
  background-color: #f5f5f5;
  box-shadow: 0px 0px 5px rgba(0,0,0,0.1);
  border: 1px solid rgb(235, 233, 253);
  border-radius: 5px;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  padding: 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
  appearance: textfield;
}

.link {
  cursor: pointer;
}

a, .link {
  text-decoration: none;
  color: #111;
}

a:hover, .link:hover {
  border-bottom: 1px solid rgb(161, 245, 65);
}

.img-link {
  border-bottom: 0 !important;
  border:none !important;
}

/* Used in the popover rows  */
.gray-hover:hover {
  background-color: #fafafa;
}

/* Enable to debug grid and adjustments
div, td {
  outline:1px red dotted;
}
td {
  outline:1px blue dotted;
}
*/
.__react_component_tooltip.show {
  opacity: 1 !important;
  font-weight: normal;
}

.tooltip-container {
  box-shadow: 3px 3px 10px #eaeaea;
  border-radius: 10px !important;
  font-weight: normal;
  opacity: 1;
  background-color: white;
  z-index: 10000;
}

.primary-icon-color > path {
  color: #024DB0;
}

.info-circle > path {
  color: #666;
}
.info-circle {
  opacity: 0.4;
  margin-left:4px;
}
.info-circle:hover {
  opacity: 0.8;
}

.exclamation-triangle > path {
  color: #fff;
}