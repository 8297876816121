.employee-sidebar-container {
  border-right: 1px solid #ddd;
  padding: 20px;
  width: 320px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: fixed;
}

.employee-sidebar-logo {
  margin-top: 50px;
  width: 180px;
  margin-bottom: 56px; /* For vertically aligning with the search box on the right/main panel */
}

.employee-sidebar-user {
  padding-top: 50px;
  padding-bottom: 50px;
  font-size: 14px;
}

.employee-sidebar-footer {
  border-top: 1px solid #ddd;
  padding-top: 50px;
  font-size: 14px;
}

.employee-sidebar-top-details {
  font-size: 12px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 20px;
  margin-bottom:20px;
}

.main-menu-item {
  display: block;
  padding:10px;
  border-radius:5px;
  text-decoration: none;
  margin-bottom:5px;
  font-size: 15px;
  border-bottom: 1px dotted #eee;
  transition: 200ms linear; 
}

.main-menu-item:hover {
  text-decoration: none;
  background-color:#f0f0f0;
  border-bottom: 1px dotted #fff;
  text-shadow: 0px 0px 2px #fff;
}

.main-menu-item.active {
  font-weight: bold;
  color: #004FB0;
}