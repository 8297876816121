.filter-button-component {
  opacity: 0.9;
  border: none;
  box-shadow: none;
  text-align: center;
  display: inline-block;
  padding: 7px 13px;
  cursor: pointer;
  border-radius: 15px;
  color: #1967D2;
  min-width: 80px;
  height: 35px;
  font-size: 14px;
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  background: #E7F0FE;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filter-button-component:hover {
  opacity: 1;
}

.filter-button-disabled {
  opacity: 0.8;
  background:#fff;
  border: 1px solid #ddd;
  color: #555;
}

.filter-button-cancel {
  color: #1967D2;
  margin-left:5px;
  border-radius: 5px;
  padding-left: 5px;
  padding-right:5px;
  padding-top:3px;
  padding-bottom:3px;
}

.filter-button-cancel:hover {
  background-color:#d5deeb;
}