.as-panel {
  width: auto;
  display: inline-block;
  margin-right:15px;
}

.as-result {
  padding: 5px;
  padding-left:10px;
  padding-right:10px;
  border-bottom: 1px dotted #eee;
  border-radius: 5px;
  cursor: pointer;
}

.as-result:hover {
  background-color: #eee;
}

.as-selected {
  display: flex;
  align-items: flex-start;
}

.as-remove-button {
  border: 1px solid #eee;
  padding: 5px;
  border-radius:10px;
  width: 22px;
  text-align: center;
  font-size: 10px;
  background-color:#eee;
  cursor: pointer;
  opacity: 0.8;
  color: #666;
  margin-left:5px;
}

.as-remove-button:hover {
  opacity: 1;
  background-color: #fafafa;
}