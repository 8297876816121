.flight-search-panel-container {
  box-shadow: 0px 5px 10px #ddd;
  padding: 20px;
  border-radius:10px;
  width: auto;
  display:inline-block;
}

.flight-search-panel-container-top-filter-row {
  display: flex;
  gap: 10px;
}

.flight-search-panel-container-bottom-filter-row {
  margin-top:15px;
  display: flex;
  gap: 10px;
}

.flight-search-panel-trip-type-dropdown {
  width: auto;
  max-width: 120px;
  font-size: 14px;
  background-color:#fff;
  box-shadow: none;
  border:1px solid #ccc;
  border-radius:5px;
  outline: none;
}
.flight-search-panel-trip-type-dropdown:hover {
  background-color:#fafafa;
  cursor: pointer;
}

.flight-search-panel-cabin-class-dropdown {
  width: auto;
  font-size: 14px;
  background-color:#fff;
  box-shadow: none;
  border:none;
  border:1px solid #ccc;
  border-radius:5px;
  outline: none;
}

.flight-search-panel-cabin-class-dropdown:hover {
  background-color:#fafafa;
  cursor: pointer;
}

.flightSearchBox {
  padding: 20px 25px;
  background-color: #f9f9fb;
  border-radius: 10px;
  width: auto;
  min-width: 875px;
  display:inline-block;
  border-bottom-right-radius: 0px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.hSeparator {
  border-left: 1px solid #ddd;
  margin-left: 20px;
  margin-right:20px;
  height: 40px;
  margin-top:20px;
}