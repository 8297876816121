.text-input {
  border: none;
  border-bottom: 1px solid #ddd;
  padding: 10px;
  font-size: 16px;
  margin-bottom: 10px;
  width: 100%;
  max-width: 400px;
}

.text-input:hover {
  border-bottom: 1px solid #004EB0;
}

.text-input:active {
  border-bottom: 1px solid #004EB0;
}

.text-input {
  border-bottom: 1px solid #004EB0;
}