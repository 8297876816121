.flight-result-row {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 890px;
  flex-basis: 100%;
  margin-bottom:10px;
  padding: 10px;
  border-radius:5px;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 0px 2px #ccc;
}
.flight-result-row:hover {
  box-shadow: 3px 3px 8px #ddd;
}

.frr-grayed-out {
  opacity: 0.4;
}

.frr-highlighted {
  box-shadow: 3px 3px 8px #ddd;
}

.flight-result-row-container {
  display: flex;
  width: 100%;
  flex-basis: 100%;
  align-items: center;
  cursor: pointer;   
}

.confirmation-row {
  display:flex;
  align-items:center;
  width: 100%;
  margin-top:10px;
  text-align: right;
  background-color: rgb(243, 245, 249);
  border-radius: 10px;
  padding-top:10px;
  padding-bottom:10px;
  padding-right:20px;
  padding-left:20px;
  font-size:13px;
}

.frr-airline-icon {
  width: 60px;
  font-size:10px;
  text-align: center;
}

.frr-timing-and-airports {
  width: 120px;
  font-size: 14px;
}

.frr-from-to-and-carrier {
  padding-right:10px;
  width: 150px;
  font-size: 14px;
}

.frr-stops {
  width: 150px;
}

.frr-co2 {
  width: 150px;
}

.frr-price {
  padding-right:30px;
}